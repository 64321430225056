import React from 'react'
import { createGlobalStyle } from 'styled-components'
import './src/assets/fonts/fonts.css'

const HideError = createGlobalStyle`
  #___gatsby ~ iframe {
    display: none;
  }
`

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {process.env.NODE_ENV === 'preview' && <HideError />}
      {element}
    </>
  )
}
